/**
 * Model that represents a Remote Session of a Google Slides export
 */
const yup = require('yup');

const {
  requiredString,
  requiredBool,
  validateObject
} = require('../utils');

const COLLECTION = 'jitsi-conferences';
const SCHEMA = yup.object().shape({
  roomId: requiredString(),
  muteAll: requiredBool().default(false)
});

async function validate(session) {
  return validateObject(SCHEMA, session);
}

module.exports = {
  COLLECTION,
  SCHEMA,
  validate
};