import React from 'react';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import GoogleLogo from '@material-ui/icons/Google';
import {useDispatch} from "react-redux";

import {login} from "../../redux/auth/actions";

import google from "../../img/auth_service_google.svg";

import './index.css';

const Login = () => {
    const dispatch = useDispatch();
    return (
        <div className="flex center">
            <Card>
                <CardContent>
                    <div>
                        <Button variant="contained" color="default" onClick={() => dispatch(login('devsontap.com'))} fullWidth={true}>
                            <img src={google} alt="google login" className="marginRight10" />Login with devsontap.com
                        </Button>
                    </div>
                    <div className="marginTop20">
                        <Button variant="contained" color="default" onClick={() => dispatch(login('voltagecontrol.com'))} fullWidth={true}>
                            <img src={google} alt="google login" className="marginRight10" />Login with voltagecontrol.com
                        </Button>
                    </div>

                </CardContent>
            </Card>

        </div>
    )
};

export default Login;