class FirestoreHelper {
  constructor(db) {
    this.db = db;
  }

  async writeObjectToFirestore(objectModel, objectToSave, merge = false) {
    const updatedAt = new Date();
    let objectId = objectToSave.id;
    const collectionRef = this.db.collection(objectModel.COLLECTION);
    const validatedObject = merge ? objectToSave : await objectModel.validate(objectToSave);
    validatedObject.createdAt = objectToSave.createdAt ? objectToSave.createdAt : updatedAt;
    validatedObject.updatedAt = updatedAt;

    if (objectId) {
      await collectionRef.doc(objectId).set(validatedObject, {
        merge
      });
    } else {
      const docRef = await collectionRef.add(validatedObject);
      objectId = docRef.id;
    }

    return {
      id: objectId,
      ...validatedObject
    };
  }

  async loadObjectFromFirestore(objectModel, objectId) {
    const docSnapshot = await this.db.collection(objectModel.COLLECTION).doc(objectId).get();
    if (!docSnapshot.exists) throw new Error("not found");
    return this.convertFromFirestoreDoc(docSnapshot);
  }

  removeObjectFromFirestore(objectModel, objectId) {
    return this.db.collection(objectModel.COLLECTION).doc(objectId).delete();
  }

  async runQuery(query) {
    const querySnapshot = await query.get();
    return querySnapshot.docs.map(this.convertFromFirestoreDoc);
  }

  watchDocument(objectModel, objectId, callback) {
    return this.db.collection(objectModel.COLLECTION).doc(objectId).onSnapshot(doc => {
      callback(this.convertFromFirestoreDoc(doc));
    });
  }

  convertFromFirestoreDoc(firestoreDocument) {
    let retVal = firestoreDocument.data() || {}; // Special case for GeoFirestore records

    if (retVal.d && retVal.g && retVal.l) {
      // then we assume we are doing a raw firestore query on a geofirestore structured document, so we pull
      // out the "data" to the top level. This is needed for when we search for deals WITHOUT specifying an
      // exact location to search by. See ecCrudOperations.searchDeals() and
      // https://github.com/geofirestore/geofirestore-js#data-structure
      retVal = retVal.d;
    }

    retVal.id = firestoreDocument.id; // convert timestamps

    if (retVal.createdAt && retVal.createdAt.toDate) {
      retVal.createdAt = retVal.createdAt.toDate();
    }

    if (retVal.updatedAt && retVal.updatedAt.toDate) {
      retVal.updatedAt = retVal.updatedAt.toDate();
    }

    return retVal;
  }

}

module.exports = FirestoreHelper;