import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import api from './api';
import reducers from './redux';
import Home from './components/home';

import './App.css';
import '@devsontap/dot-react-common/core/css/atomic.css';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00b4e8',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#4db04a',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    overrides: {
        MuiCardMedia: {
            root: {
                backgroundSize: "contain"
            }
        }
    }
});

/**
 * Create a Redux store with Thunk middleware
 */
const createStoreWithMiddleware = applyMiddleware(reduxThunk.withExtraArgument({ api }))(createStore);
const store = createStoreWithMiddleware(reducers,
    process.env.NODE_ENV === "development" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const App = () => {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <div className="App">
                        <Home />
                    </div>
                </Provider>
            </MuiThemeProvider>
        </SnackbarProvider>

    );
};

export default App;
