/* global JitsiMeetExternalAPI */
/*eslint no-undef: "error"*/

import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";

import {useConferenceByRoomSelector} from "../../../redux/conferenceRooms/selectors";
import {watchConference} from "../../../redux/conferenceRooms/actions";

import './index.css';

const JitsiMeetRoom = ({roomId, startWithVideoMuted, startWithAudioMuted, noWatch}) => {
    const [meetApi, setMeetApi] = useState(null);
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const conference = useConferenceByRoomSelector(roomId);

    const muteAll = conference ? conference.muteAll : false;

    useEffect(() => {
        if (!noWatch) {
            let cancel = null;
            dispatch(watchConference(roomId, onCancel => cancel = onCancel));
            return () => {
                if (cancel) {
                    cancel();
                }
            }
        }
    }, [roomId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (roomId) {
            const domain = "meet-jitsi.devsontap.com";
            const options = {
                roomName: roomId,
                parentNode: containerRef.current,
                configOverwrite: {
                    startWithVideoMuted,
                    startWithAudioMuted,
                    disableRemoteMute: true,
                    remoteVideoMenu: {
                        // If set to true the 'Kick out' button will be disabled.
                        disableKick: true
                    },
                    resolution: 720,
                    constraints: {
                        video: {
                            height: {
                                ideal: 720,
                                max: 720,
                                min: 240
                            }
                        }
                    },
                },
                interfaceConfigOverwrite: {
                    filmStripOnly: false,
                    TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'closedcaptions', 'fullscreen',
                        'fodeviceselection', 'hangup', 'profile', 'settings', 'raisehand', 'filmstrip',
                        'tileview', 'help'
                    ],
                    HIDE_KICK_BUTTON_FOR_GUESTS: true
                },
                onload: () => {
                    console.log("locked and loaded");
                }
            };
            const api = new JitsiMeetExternalAPI(domain, options);

            setMeetApi(api);

            return () => {
                if (meetApi) {
                    meetApi.dispose();
                }
            }
        }
    }, [roomId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (meetApi) {
            meetApi.isAudioMuted().then(audioMuted => {
                if ((muteAll && !audioMuted) || (!muteAll && audioMuted)) {
                    console.log("toggling audio", audioMuted);
                    meetApi.executeCommand('toggleAudio');
                }
            });
        }
    }, [muteAll]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={containerRef} className="h100" />
    )
};

JitsiMeetRoom.defaultProps = {
    startWithVideoMuted: false,
    startWithAudioMuted: false,
    noWatch: false
}

export default JitsiMeetRoom;