import {useShallowEqualSelector} from "../index";

/**
 * Returns the login data
 */
export const useLoginSelector = () => {
    return useShallowEqualSelector(({ login: {loggedIn, claims, loggingIn } }) => {
        return {
            loggedIn,
            claims,
            loggingIn,
        }
    });
};