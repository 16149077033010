import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import JitsiMeetApi from '@devsontap/jitsi-meet-api';

function initFirebase() {
    let config = {
        apiKey: "AIzaSyA0fVWMq2q5ORL49fAuo1LHVZppQYqbP0Q",
        authDomain: "devs-on-tap.firebaseapp.com",
        databaseURL: "https://devs-on-tap.firebaseio.com",
        projectId: "devs-on-tap",
        storageBucket: "devs-on-tap.appspot.com",
        messagingSenderId: "829146355367",
        appId: "1:829146355367:web:f4171bfa97f6fcf8fa6d82"
    };
    firebase.initializeApp(config);
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

initFirebase();
const api = new JitsiMeetApi(firebase.firestore, firebase.functions, firebase.auth, firebase.storage);

api.login = (domain) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ hd: domain });
    firebase.auth().signInWithPopup(provider);
};

export default api;