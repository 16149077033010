import {
    CONFERENCES_LOADING,
    CONFERENCES_SUCCESS,
    CONFERENCES_ERROR,
    CONFERENCE_CREATING,
    CONFERENCE_SUCCESS,
    CONFERENCE_ERROR,
    CONFERENCE_DELETED
} from "./types";


const INITIAL_STATE = {
    conferencesLoading: false,
    creatingConference: false,
    conferenceMap: {},
    conferenceIds: [],
};

export default (state = INITIAL_STATE, action) => {
    let conferenceIds = null;
    switch (action.type) {
        case CONFERENCES_LOADING:
            return {
                ...state,
                conferencesLoading: true
            };
        case CONFERENCES_SUCCESS:
            return {
                ...state,
                conferencesLoading: false,
                conferenceMap: action.payload.reduce((ret, conference) => {
                    ret[conference.id] = conference;
                    return ret;
                }, state.conferenceMap),
                conferenceIds: action.payload.map(conference => conference.id)
            };
        case CONFERENCES_ERROR: {
            return {
                ...state,
                conferencesLoading: false
            }
        }
        case CONFERENCE_CREATING:
            return {
                ...state,
                creatingConference: true
            };
        case CONFERENCE_SUCCESS:
            conferenceIds = state.conferenceIds;
            if (conferenceIds.indexOf(action.payload.id) === -1) {
                conferenceIds = [action.payload.id, ...conferenceIds];
            }
            return {
                ...state,
                creatingConference: false,
                conferenceMap: {
                    ...state.conferenceMap,
                    [action.payload.id]: action.payload
                },
                conferenceIds
            };
        case CONFERENCE_ERROR:
            return {
                ...state,
                creatingConference: false
            };
        case CONFERENCE_DELETED:
            conferenceIds = [...state.conferenceIds];
            conferenceIds.splice(state.conferenceIds.indexOf(action.payload), 1);

            return {
                ...state,
                conferenceMap: {
                    ...state.conferenceMap,
                    [action.payload]: null
                },
                conferenceIds
            };
        default:
            return state;
    }
}