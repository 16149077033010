/**
 * Utility methods, mostly around Yup schema validation
 */
const yup = require('yup');

function requiredString(message = 'required') {
  return yup.string().trim().required(message);
}

function optionalString() {
  return yup.string().trim();
}

function requiredNumber() {
  return yup.number().required();
}

function optionalNumber() {
  return yup.number();
}

function requiredDate() {
  return yup.date().required();
}

function optionalDate() {
  return yup.date();
}

function requiredObject() {
  return yup.object().required();
}

function optionalObject() {
  return yup.object().default(null).nullable();
}

function arrayOf(itemSchema) {
  return yup.array().of(itemSchema).ensure();
}

function requiredArrayOf(itemSchema) {
  return yup.array().of(itemSchema).ensure().required();
}

function optionalBool() {
  return yup.boolean();
}

function requiredBool() {
  return yup.boolean().required();
}

function lazy(func) {
  return yup.lazy(func);
}
/**
 * Validates the object against the given Yup schema with standard options.
 */


async function validateObject(schema, object) {
  return schema.validate(object, {
    abortEarly: false,
    stripUnknown: true
  });
}

module.exports = {
  requiredString,
  optionalString,
  requiredNumber,
  optionalNumber,
  requiredDate,
  optionalDate,
  requiredObject,
  optionalObject,
  arrayOf,
  optionalBool,
  requiredBool,
  lazy,
  validateObject,
  requiredArrayOf
};