import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';
import TvIcon from '@material-ui/icons/Tv';
import TvOff from '@material-ui/icons/TvOff';
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import JitsiMeetBroadcast from "../../watch/broadcast";
import {deleteConference} from "../../../redux/conferenceRooms/actions";
import {useConferenceSelector} from "../../../redux/conferenceRooms/selectors";

import './index.css';

const ConferenceItem = ({conferenceId, broadcast}) => {
    const [deleting, setDeleting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const conference = useConferenceSelector(conferenceId);

    const onDelete = () => {
        setDeleting(true);
        dispatch(deleteConference(conferenceId, setDeleting, enqueueSnackbar));
    };

    const onWatch = () => {
        window.open(`/watch/${conference.roomId}`);
    };

    return (
        <>
            <Card className="marginTop20">
                <CardContent>
                    <div>
                        <div className="exports-item-name">
                            {conference.roomId}
                        </div>
                        <div className="marginTop15">
                            {!broadcast ?
                                <TvOff fontSize="large" />
                                :
                                <JitsiMeetBroadcast roomId={conference.roomId} noWatch={true} startWithVideoMuted={true} />
                            }
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    <div className="flex">
                        <div>
                            <Button variant="text" color="secondary" onClick={onWatch}>
                                <TvIcon className="marginRight5" /> Watch Room
                            </Button>
                        </div>
                        <div className="marginLeft15">
                            {deleting ?
                                <CircularProgress size={25} />
                                :
                                <Button variant="text" color="secondary" onClick={onDelete}>
                                    <DeleteIcon className="marginRight5" /> Delete
                                </Button>
                            }
                        </div>
                    </div>
                </CardActions>

            </Card>
        </>
    )
};

export default ConferenceItem;