const models = require('./models');

const FirestoreHelper = require('./firestore');

const {
  v4: uuidv4
} = require('uuid');
/**
 * Main Api interface
 */


class RemoteSlidesApi {
  constructor(firestore, functions = null, auth = null, storage = null) {
    this.db = firestore();
    this.functions = functions ? functions() : null;
    this.firestoreHelper = new FirestoreHelper(this.db);
    this.auth = auth ? auth() : null;
    this.authProvider = auth ? new auth.GoogleAuthProvider() : null;
    this.documentIdField = firestore.FieldPath.documentId();
  }
  /**
   * TODO
   */


  createConference() {
    return this.firestoreHelper.writeObjectToFirestore(models.conference, {
      roomId: `dot-${uuidv4()}`
    });
  }

  saveConference(conference) {
    return this.firestoreHelper.writeObjectToFirestore(models.conference, conference);
  }

  muteConference(conferenceId) {
    return this.firestoreHelper.writeObjectToFirestore(models.conference, {
      id: conferenceId,
      muteAll: true
    }, true);
  }

  unMuteConference(conferenceId) {
    return this.firestoreHelper.writeObjectToFirestore(models.conference, {
      id: conferenceId,
      muteAll: false
    }, true);
  }

  async watchConferenceByRoom(roomId, callback) {
    const query = this.db.collection(models.conference.COLLECTION).where('roomId', '==', roomId);
    const results = await this.firestoreHelper.runQuery(query);

    if (results && results.length) {
      console.log("watching document");
      return this.firestoreHelper.watchDocument(models.conference, results[0].id, callback);
    }

    return null;
  }
  /**
   * TODO
   */


  getAllConferences() {
    const query = this.db.collection(models.conference.COLLECTION).orderBy('createdAt', 'asc');
    return this.firestoreHelper.runQuery(query);
  }
  /**
   * TODO
   */


  getConference(conferenceId) {
    return this.firestoreHelper.loadObjectFromFirestore(models.conference, conferenceId);
  }
  /**
   * TODO
   */


  deleteConference(conferenceId) {
    return this.firestoreHelper.removeObjectFromFirestore(models.conference, conferenceId);
  }
  /**
   * Signs the user out of Firebase
   */


  signOut() {
    if (this.auth) {
      return this.auth.signOut();
    }
  }

}

module.exports = RemoteSlidesApi;