import {
    CONFERENCES_LOADING,
    CONFERENCES_SUCCESS,
    CONFERENCES_ERROR,
    CONFERENCE_CREATING,
    CONFERENCE_SUCCESS,
    CONFERENCE_ERROR,
    CONFERENCE_DELETED
} from "./types";

/**
 * Loads all the RemoteSlidesSession objects currently in Firestore
 * @param enqueueSnackbar   notistack helper
 */
export const loadConferences = (enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CONFERENCES_LOADING });
        return api.getAllConferences()
            .then(conferences => {
                dispatch({ type: CONFERENCES_SUCCESS, payload: conferences });
            })
            .catch(err => {
                dispatch({ type: CONFERENCES_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

/**
 * Adds a new RemoteSlidesSession object in Firestore
 * @param fileId    The id of the Google Slides file in Google Drive
 * @param enqueueSnackbar   notistack helper
 */
export const createConference = (setCreatingConference, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CONFERENCE_CREATING });
        return api.createConference()
            .then(conference => {
                dispatch({ type: CONFERENCE_SUCCESS, payload: conference });
                enqueueSnackbar("Conference Created!", {variant: 'success'});
                if (setCreatingConference) {
                    setCreatingConference(false);
                }
            })
            .catch(err => {
                dispatch({ type: CONFERENCE_ERROR });
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
                if (setCreatingConference) {
                    setCreatingConference(false);
                }
            });
    }
);

/**
 * Deletes a RemoteSlidesSession object in Firestore
 * @param sessionId The id of the RemoteSlidesSession object to delete
 * @param setDeleting   Sets the 'deleting' state of the corresponding SessionItem View
 * @param enqueueSnackbar   notistack helper
 */
export const deleteConference = (conferenceId, setDeleting, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return api.deleteConference(conferenceId)
            .then(() => {
                dispatch({ type: CONFERENCE_DELETED, payload: conferenceId });
                enqueueSnackbar("Conference Deleted", {variant: 'success'});
            })
            .catch(err => {
                if (setDeleting) {
                    setDeleting(false);
                }
                enqueueSnackbar(err.message, {variant: 'error'});
                console.error(err);
            });
    }
);

export const muteAll = (conferenceIds) => (
    (dispatch, getState, { api }) => {
        return Promise.all(conferenceIds.map(conferenceId => api.muteConference(conferenceId)))
            .then(results => {
                console.log("muted all conferences")
            })
    }
);

export const unmuteAll = (conferenceIds) => (
    (dispatch, getState, { api }) => {
        return Promise.all(conferenceIds.map(conferenceId => api.unMuteConference(conferenceId)))
            .then(results => {
                console.log("unmuted all conferences")
            })
    }
);

export const watchConference = (roomId, cancelCallback) => (
    async (dispatch, getState, { api }) => {
        const callback = await api.watchConferenceByRoom(roomId, conference => {
            dispatch({type: CONFERENCE_SUCCESS, payload: conference});
        });
        console.log("callback", callback);
        return cancelCallback(callback);
    }
);