import {useShallowEqualSelector} from "../index";

export const useConferencesSelector = () => {
    return useShallowEqualSelector(({ conferenceRooms: { conferenceMap, conferencesLoading, creatingConference, conferenceIds } }) => {
        return {
            conferencesLoading,
            creatingConference,
            conferenceIds,
            roomIds: conferenceIds ? conferenceIds.map(id => conferenceMap[id].roomId) : []
        };
    });
};

export const useConferenceSelector = (conferenceId) => {
    return useShallowEqualSelector(({ conferenceRooms: { conferenceMap } }) => {
        return conferenceMap[conferenceId];
    });
};

export const useConferenceByRoomSelector = (roomId) => {
    return useShallowEqualSelector(({ conferenceRooms: { conferenceMap } }) => {
        const conferenceKey = Object.keys(conferenceMap).filter(key => conferenceMap[key].roomId === roomId)[0];
        return conferenceMap[conferenceKey];
    });
};