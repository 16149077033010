import {
    USER_AUTHED,
    USER_UNAUTHED,
} from "./types";

export const userUnauthed = () => {
    return {type: USER_UNAUTHED};
};

export const userAuthed = (claims) => (
    (dispatch) => {
        dispatch({type: USER_AUTHED, payload: claims});
    }
);

export const logout = () => (
    async (dispatch, getState, api) => {
        await api.logout()
            .then(() => localStorage.clear());
    }
);