import React, {useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@material-ui/icons/Add';
import BroadcastIcon from '@material-ui/icons/VolumeUp';
import TvIcon from '@material-ui/icons/Tv';
import StopBroadcastIcon from '@material-ui/icons/VolumeOff';
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

import ConferenceItem from "./item";
import {loadConferences, createConference, muteAll, unmuteAll} from "../../redux/conferenceRooms/actions";
import {useConferencesSelector} from "../../redux/conferenceRooms/selectors";

import './index.css';

const ConferencesDemo = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [broadcasting, setBroadcasting] = useState(false);
    const [creatingConference, setCreatingConference] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const {conferencesLoading, conferenceIds} = useConferencesSelector();

    useEffect(() => {
        dispatch(loadConferences(enqueueSnackbar));
    }, [dispatch, enqueueSnackbar]);

    const onCreateRoom = () => {
        setCreatingConference(true);
        dispatch(createConference(setCreatingConference, enqueueSnackbar));
    };

    const onBroadcastToggle = () => {
        if (broadcasting) {
            dispatch(unmuteAll(conferenceIds));
        } else {
            dispatch(muteAll(conferenceIds));
        }
        setBroadcasting(!broadcasting);
    };

    const onWatchAll = () => {
        window.open("/watch");
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    const handleOpen = () => {
        setMenuOpen(true);
    };

    const actions = [
        { icon: <AddIcon />, name: 'Add', onClick: onCreateRoom },
        { icon: broadcasting ? <StopBroadcastIcon /> : <BroadcastIcon />, name: broadcasting ? "Stop Broadcasting" : "Start Broadcasting", onClick: onBroadcastToggle },
        { icon: <TvIcon />, name: 'Watch All', onClick: onWatchAll }
    ];

    return (
        <div>
            {conferencesLoading ?
                <CircularProgress size={45} />
                :
                <div>
                    <div className="flex">
                        <div className="home-section-header text-align-left">
                            Conference Rooms
                        </div>
                        <div className="marginLeft40">
                            {creatingConference ?
                                <CircularProgress size={30} />
                                :
                                <div style={{marginTop: "-20px"}}>
                                    <Backdrop open={menuOpen} />
                                    <SpeedDial
                                        ariaLabel="SpeedDial tooltip example"
                                        icon={<SpeedDialIcon />}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        direction="right"
                                        open={menuOpen}
                                        FabProps={{ color: "secondary" }}>
                                        {actions.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={() => {
                                                    action.onClick();
                                                    handleClose();
                                                }}
                                            />
                                        ))}
                                    </SpeedDial>
                                </div>
                            }
                        </div>
                    </div>
                    {conferenceIds && conferenceIds.length ?
                        <Grid container spacing={2}>
                            {conferenceIds.map((conferenceId, index) => (
                                <Grid item key={conferenceId} xs={6} md={4} lg={3} >
                                    <ConferenceItem conferenceId={conferenceId} broadcast={broadcasting} />
                                </Grid>
                            ))}
                        </Grid>
                        :
                        <div className="marginTop20">
                            No Conferences.
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default ConferencesDemo;