import { combineReducers } from 'redux'
import { useSelector, shallowEqual } from 'react-redux';

import conferenceRooms from './conferenceRooms'
import login from './login';

const RootReducer = combineReducers({
    conferenceRooms,
    login
});

/**
 * Helper function for wrapping a selector with a shallowEqual equality function
 */
export const useShallowEqualSelector = (selector) => {
    return useSelector(selector, shallowEqual);
};


export default RootReducer;